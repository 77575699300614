import React, { useEffect, useState } from 'react'
import L from 'leaflet'
import PropTypes from 'prop-types'

import '../index.css'
import '../styles.module.css'

const tileLayers = {
  google: {
    url: 'http://mt0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
  },
  osm: {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  },
  mapbox: {
    url: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
    options: {
      id: 'mapbox/streets-v11',
      accessToken:
        'pk.eyJ1IjoiaHV5bm9uZyIsImEiOiJja3UyaGsybnUwcTJ2MnFtcGUzb2tvbW00In0.IzU0swVlpA9gwOIp-r-iyg'
    }
  }
}

const EmddiMap = ({ divId, center, zoom, tileLayer, whenCreated }) => {
  const [map, setMap] = useState(null)
  const mapDivId = divId || 'emddi-map'

  useEffect(() => {
    const _map = L.map(mapDivId).setView(center, zoom)

    L.tileLayer(tileLayers[tileLayer].url, tileLayers[tileLayer].options).addTo(
      _map
    )

    setMap(_map)
    whenCreated && whenCreated(_map)
  }, [])

  return <div id={mapDivId} className='map-container'></div>
}

EmddiMap.propTypes = {
  center: PropTypes.arrayOf(PropTypes.number).isRequired,
  zoom: PropTypes.number,
  tileLayer: PropTypes.oneOf(['mapbox', 'google', 'osm']),
  whenCreated: PropTypes.func
}

EmddiMap.defaultProps = {
  zoom: 15,
  tileLayer: 'google'
}

export default EmddiMap
